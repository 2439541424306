<template>
  <div>
    <b-card v-if='!isDataLoaded'>
      <div class='text-center text-success my-2'>
        <b-spinner class='align-middle' />
      </div>
    </b-card>

    <b-card v-else>
      <b-row>
        <b-col
          cols="12"
        >
          <div class="d-flex align-items-center justify-content-between">
            <h2 class="mb-2 text-primary">
              {{ $t('contact-details') }}
            </h2>
          </div>
          <hr class="mb-2">
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('name') }}
          </h6>
          <p>
            {{ details.name }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('email') }}
          </h6>
          <p>
            {{ details.email }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('phone') }}
          </h6>
          <p>
            {{ details.phone }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('type') }}
          </h6>
          <p>
            {{ details.type_value }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('created_at') }}
          </h6>
          <p>
            {{ formatDate(details.created_at) }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('status_value') }}
          </h6>
          <p>
            {{ details.status_value }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="12"
          lg="12"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('contact_message') }}
          </h6>
          <p>
            {{ details.message }}
          </p>
          <hr>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      details: null,
      isDataLoaded: false,
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      this.axios.get(`/contact-us/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data
          this.isDataLoaded = true
        })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${day < 10 ? `0${day}` : day}-${month < 10 ? `0${month}` : month}-${year}`
    },
  },
}
</script>
